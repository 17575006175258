import React, { useEffect, lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import qs from 'qs';
import './app.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// eslint-disable-next-line import/no-unresolved
import locales from '@shared/locales';
// eslint-disable-next-line import/no-unresolved
import Loading from '@shared/components/loading';
// eslint-disable-next-line import/no-unresolved
import Stepper from '@shared/components/stepper';
// eslint-disable-next-line import/no-unresolved
import { useAppActions, useAppState } from '@shared/app/context';

const OnboardingRouter = lazy(() => import(/* webpackChunkName: "OnboardingRouter" */ './screens/onboarding'));
const PersonalInformationScreen = lazy(() =>
    // eslint-disable-next-line import/no-unresolved
    import(/* webpackChunkName: "PersonalInformationScreen" */ '@shared/personal_information/ui')
);
// eslint-disable-next-line import/no-unresolved
const Selfie = lazy(() => import(/* webpackChunkName: "Selfie" */ '@shared/selfie/ui'));
// eslint-disable-next-line import/no-unresolved
const Documents = lazy(() => import(/* webpackChunkName: "Documents" */ '@shared/documents/ui'));
const EndScreen = lazy(() => import(/* webpackChunkName: "EndScreen" */ './screens/end'));

export const PrivateRoute = (props) => {
    const { isAuthenticate } = useAppState();
    return isAuthenticate ? <Route {...props} /> : <Redirect to="/email" />;
};

Bugsnag.start({
    apiKey: '72b575900571fab0fcc06de0f199993a',
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = () => {
    const { loading, isInApp, locale, hasStepper, step } = useAppState();
    const { load, setIsInApp, setLocale } = useAppActions();
    const { search } = useLocation();

    const startApp = async () => {
        if (window.flutter_inappwebview && !window.flutter_inappwebview.callHandler) {
            await new Promise((resolve) => {
                setTimeout(() => resolve(), 1000);
            });
        }
        if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
            const langParam = await window.flutter_inappwebview.callHandler('getLocaleName');
            const refreshToken = await window.flutter_inappwebview.callHandler('getRefreshToken');
            const availableLanguages = Object.keys(locales);
            const selectedLang = availableLanguages.find(
                (lang) =>
                    lang === langParam ||
                    lang.split('_')[0] === langParam ||
                    lang.split('_')[0] === langParam.split('_')[0] ||
                    lang.split('_')[0] === langParam.split('-')[0]
            );
            if (selectedLang) {
                setLocale(selectedLang);
            }
            setIsInApp(true);
            load(refreshToken);
        } else if (search) {
            const { apps, token, lang, tk } = qs.parse(search.replace('?', ''));
            if (apps) {
                const isInAppParam = !!apps;
                if (isInAppParam !== isInApp) {
                    setIsInApp(isInAppParam);
                }
            }
            if (lang && Object.keys(locales).find((l) => lang === l)) {
                setLocale(lang);
            }
            if (tk && !sessionStorage.getItem('businessToken')) {
                sessionStorage.setItem('businessToken', tk);
            }
            load(token);
        } else {
            load();
        }
    };

    useEffect(() => {
        startApp();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <ErrorBoundary>
            <IntlProvider messages={locales[locale]} locale={locale.replace('_', '-')} defaultLocale="en">
                <Box
                    sx={{
                        display: 'grid',
                        position: 'relative',
                        maxWidth: 444,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingBottom: 'env(safe-area-inset-bottom)',
                        overflow: 'auto',
                    }}
                >
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            <Redirect exact from="/" to="/onboarding/start" />
                            <Route path="/onboarding" component={OnboardingRouter} />
                            <PrivateRoute path="/selfie" component={Selfie} />
                            <PrivateRoute path="/driving-licence" component={Documents} />
                            <PrivateRoute path="/identity-card" component={Documents} />
                            <PrivateRoute exact path="/personal-information" component={PersonalInformationScreen} />
                            <PrivateRoute exact path="/end" component={EndScreen} />
                            <Redirect to="/onboarding/start" />
                        </Switch>
                    </Suspense>
                    {hasStepper && <Stepper step={step} steps={6} />}
                </Box>
            </IntlProvider>
        </ErrorBoundary>
    );
};

export default App;
